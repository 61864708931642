
import {Options, Vue} from 'vue-class-component';
import ProxyCommon from '@/web/operation/proxy/common/index.vue';

@Options({
  components: {
    ProxyCommon
  }
})
export default class ProxyOldBoy extends Vue {
  title = 'ui_text_076';
  settings = {
    active_day: {
      name: 'ui_text_031',
      unitTxt: 'ui_day',
      items: [1, 5, 7, ''],
      itemHilight: 0,
      customItem: [3]
    },
    not_login_day: {
      name: 'ui_text_077',
      unitTxt: 'ui_day',
      subNote: 'ui_text_078',
      items: [1, 5, 7, ''],
      itemHilight: 0,
      customItem: [3]
    },
    threshold: {
      name: 'ui_text_032',
      items: [100, 1000, 5000, ''],
      itemHilight: 0,
      customItem: [3]
    },
    price: {
      name: 'ui_goal_text_032',
      unitTxt: 'ui_dollar',
      items: [1, 10, 100, ''],
      itemHilight: 0,
      customItem: [3]
    },
    num: {
      name: 'ui_goal_text_035',
      unitTxt: 'ui_each',
      items: [10, 30, 50, ''],
      itemHilight: 0,
      customItem: [3]
    },
    choose_user: {
      name: 'ui_send_object',
      items: ['ui_text_043'],
      itemHilight: 0,
      customItem: [0],
      isMember: true
    }
  };
  info = {
    type: 3,
    active_day: 1,
    not_login_day: 1,
    price: 0,
    num: 0,
    threshold: 0
  };
}
